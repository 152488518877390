import React from 'react';
import PropTypes from 'prop-types';
import Script from 'nordic/script';
import { SiteFavicon } from '../../helpers/siteFavicon';

const NotFound = ({ basePath }) => (
  <>
    <SiteFavicon />
    <Script>{`window.location.href="${basePath}";`}</Script>
  </>
);

NotFound.propTypes = {
  basePath: PropTypes.string,
};

export const getServerSideProps = async (req, res) => {
  res.status(404);
  return {
    props: {
      basePath: `${req.baseUrl || '/'}`,
    },
  };
};

export default NotFound;
